import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate'; // Pagination

const ArealList = () => {
    const [areals, setAreals] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [view, setView] = useState('card'); // Zustand für die Ansicht (Kartenansicht oder Listenansicht)
    const itemsPerPage = 6; // Anzahl der Einträge pro Seite

    const fetchAreals = async (search = '', page = 1) => {
        try {
            setLoading(true);

            // Anfrage mit Suchparametern an das Backend
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/areal/list`, {
                params: {
                    search,
                    page
                }
            });

            //setAreals(response.data);
            const arealsWithImages = await Promise.all(response.data.map(async (areal) => {
                if (areal.imageOrder && areal.imageOrder.length > 0) {
                    try {
                        return { ...areal, imageUrl: `${process.env.REACT_APP_API_URL}/files/${areal._id}/images/${areal.imageOrder[0]}` };
                    } catch (error) {
                        console.error(`Fehler beim Laden des Bildes für Areal ${areal._id}:`, error);
                        return { ...areal, imageUrl: null };
                    }
                }
                return areal;
            }));
            setAreals(arealsWithImages);
            setLoading(false);
        } catch (error) {
            console.error("Fehler beim Abrufen der Konzeptvergaben:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAreals();
    }, []);

    const handleSearch = () => {
        fetchAreals(searchQuery);
    };

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
        fetchAreals(searchQuery, data.selected + 1);
    };

    const displayedAreals = areals.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    const toggleView = () => {
        setView(view === 'card' ? 'list' : 'card');
    };

    return (
        <div className="container mt-5">
            <h2 className="text-center mb-4">Alle Konzeptvergaben</h2>

            <div className="mb-4 d-flex align-items-center">
                <input
                    type="text"
                    placeholder="Suche nach Konzeptvergaben..."
                    className="form-control"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                &nbsp;
                <button
                    className="btn btn-primary ml-2"
                    onClick={handleSearch}
                >
                    Suchen
                </button>
            </div>
            <a
                href="#"
                className="greenlink"
                onClick={toggleView}
            >
                {view === 'card' ? 'Liste' : 'Karten'}
            </a>

            <div className={`row ${view === 'card' ? 'd-flex' : ''}`}>
                {loading ? (
                    <p className="text-center">Laden...</p>
                ) : displayedAreals.length > 0 ? (
                    displayedAreals.map((areal) => (
                        <div
                            className={`col-md-${view === 'card' ? '4' : '12'} mb-4`}
                            key={areal._id}
                        >
                            {view === 'card' ? (
                                <div className="card">
                                    {areal.imageOrder && areal.imageOrder.length > 0 && areal.imageUrl ? (
                                        <div className="card-img-container">
                                        <img
                                            src={`${areal.imageUrl}`}
                                            className="card-img-top"
                                            alt="Areal"
                                        />
                                        </div>
                                    ) : null}
                                    <div className="card-body">
                                        <h5 className="card-title">{areal.name}</h5>
                                        <p className="card-text">{areal.comment}</p>
                                        <a href={`/areal/${areal._id}`} className="greenlink">Mehr erfahren</a>
                                    </div>
                                </div>
                            ) : (
                                <div className="list-item">
                                    <div className="d-flex align-items-center">
                                        {areal.imageOrder && areal.imageOrder.length > 0 && areal.imageUrl ? (

                                            <img
                                                src={`${areal.imageUrl}`}
                                                className="list-item-img"
                                                alt="Areal"
                                            />
                                        ) : null}
                                        <div className="ml-3">
                                            <h5>{areal.name}</h5>
                                            {/*<p className="text-muted">{areal.minprice} €</p>*/}
                                            <p>{areal.comment}</p>
                                            <a href={`/areal/${areal._id}`} className="greenlink">Mehr erfahren</a>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))
                ) : (
                    <p className="text-center">Keine Konzeptvergaben gefunden.</p>
                )}
            </div>

            {/* Pagination */}
            <div className="mt-4">
                <ReactPaginate
                    previousLabel={'← Zurück'}
                    nextLabel={'Weiter →'}
                    breakLabel={'...'}
                    pageCount={Math.ceil(areals.length / itemsPerPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
            </div>
        </div>
    );
};

export default ArealList;
