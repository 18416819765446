import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h5>Konzeptvergabe.de</h5>
                    </div>
                    <div className="col-md-6 text-md-end">
                        <ul className="list-unstyled">
                            <li>
                                <Link to="/impressum">Impressum</Link>
                            </li>
                            <li>
                                <Link to="/datenschutz">Datenschutz</Link>
                            </li>
                            <li>
                                <Link to="/kontakt">Kontakt</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
