import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const ConfirmationPage = () => {
    const { token } = useParams();
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const confirmAccount = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/confirm/${token}`);
                setMessage(response.data.message);
            } catch (error) {
                setErrorMessage('Fehler bei der Bestätigung: ' + error.response.data.message);
            }
        };
        confirmAccount();
    }, []);

    return (
        <div className="container main-content mt-5 text-center" >
            {errorMessage && (
                <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>
            )}

            {/* Erfolgsmeldung anzeigen */}
            {message && (
                <div className="alert alert-success" role="alert">
                    {message}
                </div>
            )}
        </div>
    );
};

export default ConfirmationPage;
