import React, { useState } from 'react';
import axios from 'axios';

const ToggleStatus = ({ arealId, initialStatus, onStatusChange }) => {
    const [currentStatus, setCurrentStatus] = useState(initialStatus);
    const [loading, setLoading] = useState(false);

    const handleClick = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('authToken');
            const newStatus = currentStatus === 1 ? 0 : 1;

            await axios.put(`${process.env.REACT_APP_API_URL}/areal/${arealId}`,
                { field: 'status', value: newStatus },
                { headers: { 'Authorization': `Bearer ${token}` } }
            );

            setCurrentStatus(newStatus);
            if (onStatusChange) onStatusChange(newStatus); // Optionaler Callback für das Dashboard
        } catch (error) {
            console.error('Fehler beim Umschalten des Status:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <a
            className={`status-link ${currentStatus === 1 ? 'text-success' : 'text-warning'}`}
            onClick={handleClick}
            disabled={loading}
        >
            {loading ? (
                <span className={'text-muted'}>Bitte warten...</span>
            ) : currentStatus === 1 ? (
                <>
                    <i className="fas fa-eye"></i> Veröffentlicht
                </>
            ) : (
                <>
                    <i className="fas fa-eye-slash"></i> Privat
                </>
            )}
        </a>
    );
};

export default ToggleStatus;
