import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
const Home = () => {
    const navigate = useNavigate();
   // const [userrole, setUserrole] = useState("Nicht angemeldet");
    const [areals, setAreals] = useState([]);

    useEffect(() => {
       /* const fetchUserrole = () => {
            const token = localStorage.getItem('authToken');
            if (token) {
                try {
                    const decodedToken = jwtDecode(token);
                    setUserrole(decodedToken.userrole || "Nicht angemeldet");
                } catch (error) {
                    console.error("Fehler beim Dekodieren des Tokens:", error);
                    setUserrole("Nicht angemeldet");
                }
            }
        };*/

        const fetchAreals = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/areal/home`);
                //setAreals(response.data);
                const arealsWithImages = await Promise.all(response.data.map(async (areal) => {
                    if (areal.imageOrder && areal.imageOrder.length > 0) {
                        try {
                            return { ...areal, imageUrl: `${process.env.REACT_APP_API_URL}/files/${areal._id}/images/${areal.imageOrder[0]}` };
                        } catch (error) {
                            console.error(`Fehler beim Laden des Bildes für Areal ${areal._id}:`, error);
                            return { ...areal, imageUrl: null };
                        }
                    }
                    return areal;
                }));
                setAreals(arealsWithImages);
            } catch (error) {
                console.error(error);
            }
        };

        //fetchUserrole();
        fetchAreals();
    }, []);

    return (
        <div className="container mt-5">
            <h2 className="text-center mb-4">Willkommen bei Konzeptvergabe.de!</h2>
            <h5 className="text-center">Wir sind bald soweit!</h5>
            <br/>
            <p className="text-center">
                Bewerben Sie als Kommune Ihre Grundstücksangebote.
                <br/>
                <br/>
                Finden Sie als Bewerber das passende Grundstück und verwalten Sie Ihre Angebote.
            </p>

            <div className="row">
                {areals.length > 0 ? (
                    areals.map((areal) => (
                        <div className="col-md-4" key={areal._id}>
                            <div className="card mb-4">
                                {areal.imageOrder && areal.imageOrder.length > 0 && areal.imageUrl ? (
                                    <div className="card-img-container">
                                    <img
                                        src={`${areal.imageUrl}`}
                                        className="card-img-top"
                                        alt="Areal"
                                    />
                                    </div>
                                ) : null}
                                <div className="card-body">
                                    <h5 className="card-title">{areal.name}</h5>
                                    <p className="card-text">{areal.comment}</p>
                                    {/* Link zur Detailseite des Areals */}
                                    <a href={`/areal/${areal._id}`} className="greenlink">Mehr erfahren</a>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (<></>
                    //<p className="text-center">Keine Inserate verfügbar.</p>
                )}
            </div>

            <div className="text-center">
                <button className="btn btn-success mt-4" onClick={() => navigate('/register')}>Jetzt registrieren und
                    bewerben
                </button>
            </div>
        </div>
    );
};

export default Home;
