import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-modal';
import ToggleStatus from "./toggle-status.component";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FaTrash, FaUpload } from 'react-icons/fa';
import heic2any from 'heic2any';

const ArealPreview = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [areal, setAreal] = useState(null);
    const [loading, setLoading] = useState(true);

    const [currentField, setCurrentField] = useState('');
    const [fieldValue, setFieldValue] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalType, setModalType] = useState('');
    const [images, setImages] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '600px'
        },
    };

    useEffect(() => {
        const fetchAreal = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/areal/preview/${id}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                setAreal(response.data);

                const [imagesResponse, documentsResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_API_URL}/files/${id}/private/images`, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    }),
                    axios.get(`${process.env.REACT_APP_API_URL}/files/${id}/private/documents`, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    })
                ]);

                const imageRequests = imagesResponse.data.map(async (fileName) => {
                    const imageResponse = await axios.get(`${process.env.REACT_APP_API_URL}/files/${id}/private/images/${fileName}`, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
                    return {
                        original: `${imageResponse.data.image}`,
                        thumbnail: `${imageResponse.data.image}`,
                        fileName: fileName, // Hinzufügen des Dateinamens
                    };
                });

                const formattedImages = await Promise.all(imageRequests);
                setImages(formattedImages);
                setDocuments(documentsResponse.data);
                setLoading(false);
            } catch (error) {
                setSuccessMessage(null);
                setErrorMessage("Areal konnte nicht gefunden werden");
                setLoading(false);
            }
        };
        fetchAreal();
    }, [id]);

    const openModal = (field, title, type) => {
        setModalTitle(title + " bearbeiten")
        setModalType(type);
        setCurrentField(field);
        setFieldValue(areal[field]);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setModalTitle('')
        setModalType('');
        setCurrentField('');
        setFieldValue('');
    };

    const handleOnDragEnd = async (result) => {
        setErrorMessage(null);
        setSuccessMessage(null);
        if (!result.destination) return;

        const reorderedImages = Array.from(images);
        const [movedImage] = reorderedImages.splice(result.source.index, 1);
        reorderedImages.splice(result.destination.index, 0, movedImage);

        setImages(reorderedImages);

        const newOrder = reorderedImages.map(img => img.fileName);

        try {
            const token = localStorage.getItem('authToken');
            await axios.put(`${process.env.REACT_APP_API_URL}/files/${id}/images/order`, {
                imageOrder: newOrder
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
        } catch (error) {
            setErrorMessage("Fehler beim Aktualisieren der Bildreihenfolge");
            console.error('Fehler beim Aktualisieren der Bildreihenfolge:', error);
        }
    };

    const handleSave = async () => {
        setErrorMessage(null);
        setSuccessMessage(null);
        try {
            const token = localStorage.getItem('authToken');
            await axios.put(`${process.env.REACT_APP_API_URL}/areal/${id}`, {
                field: currentField,
                value: fieldValue
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setAreal({ ...areal, [currentField]: fieldValue }); // Wert im Frontend aktualisieren
            setErrorMessage(null);
            setSuccessMessage("Gespeichert");
            closeModal();
        } catch (error) {
            setSuccessMessage(null);
            setErrorMessage("Fehler beim Speichern.");
            console.error('Fehler beim Speichern:', error);
        }
    };

    const handleDeleteImage = async (fileName) => {
        setErrorMessage(null);
        setSuccessMessage(null);
        if (!window.confirm('Sind Sie sicher, dass Sie dieses Bild löschen möchten?')) return;

        try {
            const token = localStorage.getItem('authToken');
            await axios.delete(`${process.env.REACT_APP_API_URL}/files/${id}/private/images/${fileName}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setImages(images.filter(img => img.fileName !== fileName));
            setErrorMessage(null);
            setSuccessMessage("Bild gelöscht.")
        } catch (error) {
            setSuccessMessage(null);
            setErrorMessage(error);
            console.error('Fehler beim Löschen des Bildes:', error);
        }
    };

    const handleUploadImages = async (event) => {
        setErrorMessage(null);
        setSuccessMessage(null);
        const files = event.target.files;
        if (!files.length) return;

        const MAX_SIZE_MB = 10;
        const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;

        const MAX_IMAGES_COUNT = 10;

        if (files.length > MAX_IMAGES_COUNT) {
            setErrorMessage(`Du kannst maximal ${MAX_IMAGES_COUNT} Bilder hochladen.`);
            return;
        }

        let totalSize = 0;
        for (let file of files) {
            totalSize += file.size;
        }

        if (totalSize > MAX_SIZE_BYTES) {
            setErrorMessage(`Die Gesamtgröße der ausgewählten Bilder überschreitet ${MAX_SIZE_MB} MB.`);
            return;
        }

        const formData = new FormData();
        const convertedFiles = [];


        for (let file of files) {
            if (file.type === 'image/heic' || file.name.endsWith('.heic')) {
                try {
                    const convertedBlob = await heic2any({
                        blob: file,
                        toType: 'image/jpeg',
                    });

                    // Erstelle eine neue JPEG-Datei aus dem konvertierten Blob
                    const convertedFile = new File([convertedBlob], file.name.replace('.heic', '.jpg'), {
                        type: 'image/jpeg',
                    });

                    convertedFiles.push(convertedFile);
                    formData.append('images', convertedFile);
                } catch (error) {
                    console.error('Fehler bei der HEIC-Konvertierung:', error);
                }
            } else {
                // Normale Dateien unverändert hinzufügen
                convertedFiles.push(file);
                formData.append('images', file);
            }
        }

        try {
            //setUploading(true);
            const token = localStorage.getItem('authToken');
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/files/${id}/private/images`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);   //upload progress bar
                    //setUploadProgress(percentCompleted);
                }
            });
            const fetchImagesWithAuth = async () => {
                try {
                    const newImages = await Promise.all(
                        response.data.newImages.map(async (fileName) => {
                            const imageUrl = `${process.env.REACT_APP_API_URL}/files/${id}/private/images/${fileName}`;
                            // GET-Anfrage für jedes Bild mit Token im Header
                            const result = await axios.get(imageUrl, {
                                headers: {
                                    Authorization: `Bearer ${token}`
                                }
                            });
                            return {
                                original: result.data.image,
                                thumbnail: result.data.image,
                                fileName: fileName,
                            };

                        })
                    );
                    setImages([...images, ...newImages]);
                    setErrorMessage(null);
                    setSuccessMessage("Upload erfolgreich!");
                } catch (error) {
                    setSuccessMessage(null);
                    setErrorMessage(error.message);
                    console.error("Fehler beim Laden der Bilder:", error);
                }
            };
            fetchImagesWithAuth();
        } catch (error) {
            setSuccessMessage(null);
            setErrorMessage(error.message);
            console.error('Fehler beim Hochladen der Bilder:', error);
        } /*finally {           //upload progress bar
            setUploading(false);
            setUploadProgress(0);
        }*/
    };

    const handleDeleteDocument = async (docName) => {
        setErrorMessage(null);
        setSuccessMessage(null);
        if (!window.confirm('Sind Sie sicher, dass Sie dieses Dokument löschen möchten?')) return;

        try {
            const token = localStorage.getItem('authToken');
            await axios.delete(`${process.env.REACT_APP_API_URL}/files/${id}/private/documents/${docName}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setDocuments(documents.filter(doc => doc !== docName));
            setErrorMessage(null);
            setSuccessMessage("Dokument gelöscht.");
        } catch (error) {
            setSuccessMessage(null);
            setErrorMessage("Fehler beim Löschen des Dokuments.");
            console.error('Fehler beim Löschen des Dokuments:', error);
        }
    };

    const handleUploadDocuments = async (event) => {
        setErrorMessage(null);
        setSuccessMessage(null);
        const files = event.target.files;
        if (!files.length) return;

        const MAX_SIZE_MB = 10;
        const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;

        const MAX_DOCUMENT_COUNT = 10;

        if (files.length > MAX_DOCUMENT_COUNT) {
            setErrorMessage(`Du kannst maximal ${MAX_DOCUMENT_COUNT} Dokumente hochladen.`);
            return;
        }

        let totalSize = 0;
        for (let file of files) {
            totalSize += file.size;
        }

        if (totalSize > MAX_SIZE_BYTES) {
            setErrorMessage(`Die Gesamtgröße der ausgewählten Dokumente überschreitet ${MAX_SIZE_MB} MB.`);
            return;
        }

        const formData = new FormData();
        for (let file of files) {
            formData.append('documents', file);
        }

        try {
            const token = localStorage.getItem('authToken');
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/files/${id}/private/documents`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            setDocuments([...documents, ...response.data.newDocuments]);
            setErrorMessage(null);
            setSuccessMessage("Dokumente erfolgreich hochgeladen!");
        } catch (error) {
            setSuccessMessage(null);
            setErrorMessage("Fehler beim Hochladen der Dokumente.");
            console.error('Fehler beim Hochladen der Dokumente:', error);
        }
    };

    if (loading) {
        return <div className="container mt-5 text-center"><h2>Laden...</h2></div>;
    }

    return (
        <div className="container mt-5">
            {/* Fehlermeldung anzeigen */}
            {errorMessage && (
                <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>
            )}

            {/* Erfolgsmeldung anzeigen */}
            {successMessage && (
                <div className="alert alert-success" role="alert">
                    {successMessage}
                </div>
            )}
            <div className="row">
                <div className="col-md-8">
                    <div className="card mb-4">
                        {images.length > 0 && (
                                <DragDropContext onDragEnd={handleOnDragEnd}>
                                    <Droppable droppableId="images" direction="horizontal">
                                        {(provided) => (
                                            <div
                                                className="image-gallery-container"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}>
                                                {images.map((image, index) => (
                                                    <Draggable key={image.fileName}
                                                               draggableId={image.fileName} index={index}>
                                                        {(provided) => (
                                                            <div
                                                                className={"draggable-img"}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={{
                                                                    ...provided.draggableProps.style,
                                                                }}
                                                            >
                                                                <img src={image.original}
                                                                     alt={`Areal Image ${index + 1}`}
                                                                     className="img-thumbnail"/>
                                                                <FaTrash
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: '5px',
                                                                        right: '5px',
                                                                        color: 'red',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    onClick={() => handleDeleteImage(image.fileName)}
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                        )}

                        <div className="card-body">
                            <div className="upload-section mb-3">
                                <label htmlFor="image-upload" className="btn btn-primary">
                                    <FaUpload/> Bilder hochladen
                                </label>
                                <input
                                    type="file"
                                    id="image-upload"
                                    multiple
                                    accept=".jpeg, .jpg, .png"
                                    style={{display: 'none'}}
                                    onChange={handleUploadImages}
                                />
                                {/*}            //upload progress bar
                                {uploading && (
                                    <div>
                                        <div className="progress mt-2">
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{width: `${uploadProgress}%`}}
                                                aria-valuenow={uploadProgress}
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                            >
                                                {uploadProgress}%
                                            </div>
                                        </div>
                                    </div>

                                )}{*/}
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="title-container card-text">
                                    <h3 className="card-title">{areal.name}</h3>
                                    &nbsp;<a className="greenlink"
                                             onClick={() => openModal('name', 'Name', 'text')}>Bearbeiten</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <p className="card-text">
                                        <strong>Nutzung:</strong> {areal.usage}
                                        &nbsp;<a className="greenlink"
                                                 onClick={() => openModal('usage', 'Nutzung', 'text')}>Bearbeiten</a>
                                    </p>
                                    <p className="card-text">
                                        <strong>Baurecht:</strong> {areal.law}
                                        &nbsp;<a className="greenlink"
                                                 onClick={() => openModal('law', 'Baurecht', 'text')}>Bearbeiten</a>
                                    </p>
                                    <p className="card-text card-last-with-margin display-linebreak">
                                        <strong>Baurecht Kommentar:</strong> {areal.lawcomment}
                                        &nbsp;<a className="greenlink"
                                                 onClick={() => openModal('lawcomment', 'Baurecht Kommentar', 'text')}>Bearbeiten</a>
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <p className="card-text display-linebreak">
                                        <strong>Kommentar:</strong> {areal.comment}
                                        &nbsp;<a className="greenlink"
                                                 onClick={() => openModal('comment', 'Kommentar', 'text')}>Bearbeiten</a>
                                    </p>
                                    <p className="card-text">
                                        <strong>Mindestpreis:</strong> {areal.minprice} €
                                        &nbsp;<a className="greenlink"
                                                 onClick={() => openModal('minprice', 'Mindestpreis', 'number')}>Bearbeiten</a>
                                    </p>
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <p className="card-text display-linebreak">
                                    <strong>Beschreibung:</strong><br/> {areal.description}
                                    &nbsp;<a className="greenlink"
                                             onClick={() => openModal('description', 'Beschreibung')}>Bearbeiten</a>
                                </p>
                                <p className="card-text">
                                    <ToggleStatus arealId={areal._id} initialStatus={areal.status}/>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-header">
                            <h5>Dokumente</h5>
                        </div>
                        <div className="card-body">
                            <label htmlFor="document-upload" className="btn btn-primary">
                                <FaUpload/> Dokumente hochladen
                            </label>
                            <input
                                type="file"
                                id="document-upload"
                                multiple
                                accept=".pdf"
                                style={{display: 'none'}}
                                onChange={handleUploadDocuments}
                            />
                            <div className="document-list mt-3">
                                {documents.map((doc, index) => (
                                    <div key={index}>
                                        <FaTrash
                                            style={{
                                                color: 'red',
                                                cursor: 'pointer',
                                                float: 'right'
                                            }}
                                            onClick={() => handleDeleteDocument(doc)}
                                        />
                                        <br/>
                                        <p className="mb-0">{doc}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
                <h6>{modalTitle}</h6>
                {(currentField === 'comment' || currentField === 'lawcomment' || currentField === 'description') ? (
                    <textarea
                        className="modal-input form-control"
                        value={fieldValue}
                        onChange={(e) => setFieldValue(e.target.value)}
                        rows="4"
                    />
                ) : currentField === 'usage' ? (
                    <select
                        className="modal-input form-control"
                        value={fieldValue}
                        onChange={(e) => setFieldValue(e.target.value)}
                    >
                        <option value="Test Nutzung A">Test Nutzung A</option>
                        <option value="Test Nutzung B">Test Nutzung B</option>
                        <option value="Test Nutzung C">Test Nutzung C</option>
                    </select>
                ) : (
                    <input
                        className="modal-input form-control"
                        type={modalType}
                        value={fieldValue}
                        onChange={(e) => setFieldValue(e.target.value)}
                    />
                )}
                <div className="modal-buttons mt-3">
                    <button onClick={handleSave} className="btn btn-success">Speichern</button>
                    &nbsp;
                    <button onClick={closeModal} className="btn btn-secondary">Abbrechen</button>
                </div>
            </Modal>

            <div className="mt-5 text-center">
                <button className="btn btn-secondary" onClick={() => navigate(-1)}>Zurück zur Übersicht</button>
            </div>
        </div>
    );
};

export default ArealPreview;
